.tabs {
    display: flex;
    gap: 14px;

    background-color: rgba(white, 0.25);
    backdrop-filter: blur(3px);

    border-radius: 12px;
    
    padding: 10px;
    margin: 12px;
    margin-top: 50px;
}

.tab {
    position: relative;

    text-decoration: none;
    
    z-index: 100;
}

.tab-tile {
    display: flex;
    
    padding: 18px;

    cursor: pointer;
    
    svg {
        color: #222;
    }

    transition: all 0.2s ease-out;
}

.tab.selected .tab-tile {
    transform: translateY(-50%);

    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0, 0, 0, 2);
}

// STYLES FOR THE TAB TILE BACKGROUND 'SHAPE'

.tab-tile::before {
    content: '';
    
    position: absolute;
    top: 0;
    left: 0;
    
    z-index: -1;
    
    height: 100%;
    width: 100%;

    border-radius: 10px;

    opacity: 0.9;

    transition: all 0.2s;
}

.bookmarks-tab .tab-tile::before {
    background: linear-gradient(45deg, #f8b5a5, #e2d2fd);
}

.todos-tab .tab-tile::before {
    background: linear-gradient(45deg, #a2ffff, #ffbdde);
}

.notes-tab .tab-tile::before {
    background: linear-gradient(45deg, #d2cdff, #5fffbc);
}

.tab-tile:hover::before {
    opacity: 1;
    transform: scale(1.1);
}

.tab.selected .tab-tile::before {
    opacity: 1;
    transform: scale(1.1);
    border-radius: 50%;

    box-shadow: 0px 7px 17px rgba(black, 0.12);

    transition-delay: 0.2s;
}

// END OF TAB TILE BACKGROUND 'SHAPE' STYLES




// STYLES FOR THE UNDERLINE EFFECT

.tab::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;

    height: 3px;
    width: 50%;

    margin: auto;

    background-color: rgba(white, 0.85);

    transition: all 0.2s;
    
    // hide by default
    opacity: 0;
    transform: scaleX(0);
}

.tab.selected::after {
    opacity: 1;
    transform: scaleX(1);
    transition-delay: 0.2s;
}

.tab.selected { outline: none; }

@media(max-width:900px){
	.tabs {
    gap: 50px;
	}
}