.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 2000ms ease-out, transform 1000ms ease-out,
      visibility 2000ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  #bubbles {
    display: inline-block;
    font-family: arial;
    position: relative;
    }
    
#bubbles a {
  position: relative;
  margin: 1em 0 0;
  font-family: 'Luckiest Guy', cursive;
  z-index: 2;
}
    
.individual-bubble {
  position: absolute;
  border-radius: 100%;
  bottom: 10px;
  background-color: #fff;
  z-index: 1;
}